import React from "react";
import { withRouter } from "react-router";
import './Tarot.css';

class Tarot extends React.Component {
  constructor(props) {
    super(props);

    const { match /*, location, history */} = props;
    this.state = {smsCode: match["params"]["smsCode"]};

    this.state = {
      version: match["params"]["version"] ? match["params"]["version"] : null,
      isTarotLanding2: match['params']['randomString'] ? true : false,
      landingPage2String: match['params']['randomString'],
      selectedCards: this._checkHasPreviousResult() ? JSON.parse(localStorage.getItem('selectedCards')) : [], 
      resultSMSCode: null,
      cards: [
        {number: 0, id: 0},
        {number: 1, id: 1},
        {number: 2, id: 2},
        {number: 3, id: 3},
        {number: 4, id: 4},
        {number: 5, id: 5},
        {number: 6, id: 6},
        {number: 7, id: 7},
        {number: 8, id: 8},
        {number: 9, id: 9},
        {number: 10, id: 10},
        {number: 11, id: 11}
      ]
    };
    
    this.selectCard = this.selectCard.bind(this);
    this.shuffleCards = this.shuffleCards.bind(this);

    this.rootRef = null;
    this.titleRef = null;
    this.titleResultRef = null;
    this.cardsWrapperRef = null;
    this.analizaWrapperRef = null;
    this.cardRefs = {};
    this.vidovnjakinjaRef = null;

    this.resultSubTitleRef = null;
    this.resultDescRef = null;
    this.resultQuestionRef = null;
    this.resultAnswerRef = null;
    this.resultSMSRef = null;
    this.resultSMSMobileRef = null;
  }
  _checkHasPreviousResult() {
    var selectedCards = localStorage.getItem('selectedCards');
    var selectedCardsExpire = localStorage.getItem('selectedCardsExpire');
    if(selectedCards != null && selectedCardsExpire != null) {
      selectedCardsExpire = new Date(JSON.parse(selectedCardsExpire));
      if(selectedCardsExpire > new Date( Date.now() - 10 * 60000 )) {
        return true;
      }
    }
  }
  componentDidMount() {
    var vidovnjakinjaShow = localStorage.getItem('vidovnjakinjaShow');
    if(!vidovnjakinjaShow) {
      // this.vidovnjakinjaRef.style.display = 'none';
      localStorage.setItem('vidovnjakinjaShow', true);
    }
    if(this.state.selectedCards.length > 0) {
      this.rootRef.classList.add('no-anim');
      let cardPosition = 1;
      for(const selectedCardIndex of this.state.selectedCards) {
        this._adjustSelectedCard(cardPosition, this.cardRefs[selectedCardIndex]);
        cardPosition++;
      }
      var selectedCardsResult = localStorage.getItem('selectedCardsResult');
      this.displayResults(JSON.parse(selectedCardsResult), true);
    }
  }
  render() {
    return (
      <div ref={(instance)=>{this.rootRef = instance;}} className="wrapper">
        <div class="header">
            <nav class="navbar navbar-default">
                <div class="container">
                    <div class="navbar-header">
                        <a class="navbar-brand" href={this.state.isTarotLanding2 ? "/" + this.state.landingPage2String : "/"}><img src={`${process.env.PUBLIC_URL}/img/logo.png`} width="200px"/></a>
                    </div>
                </div>
            </nav>
        </div>
        <div class="main-content">
            <div class="container">
                <div className="title-wrapper">
                  <div className="title" ref={(instance)=>{this.titleRef = instance;}}>
                    <h1>BESPLATNO ČITANJE TAROTA</h1>
                    <h4>Saznajte o svojoj ljubavi, vezama, finansijama ili karijeri</h4>
                    <div class="space"></div>
                    <h5>Odaberite 3 karte iz špila ispod</h5>
                    <div class="space"></div>
                  </div>
                  <div className="title-result" ref={(instance)=>{this.titleResultRef = instance;}}>
                    <h2>ŠTA VAM KAŽU KARTE</h2>
                  </div>
                </div>
                <div className="cards-wrapper" ref={(instance)=>{this.cardsWrapperRef = instance;}}>
                  <div className="cards-row">
                      {this.state.cards.map((card, index) => {
                        return (
                          <div id={card.id} style={{zIndex: card.number, top: "5.4%", left: this._getCardLeft(card.number)}} alt="" ref={(instance)=>{this.cardRefs[index] = instance;}} onClick={this.selectCard} index={card.number} className="card">
                            <div className="card-title"></div>
                            <div className="card-inner">
                              <div className="card-flip-front"><img alt="" className="img-responsive" src="/img/cards/card-back.png"/></div>
                              <div className="card-flip-back"><img alt="" className="img-responsive"/></div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div className="ghost-cards hide-on-mobile">
                    <img src="img/cards/card-back.png" class="card card-selected img-responsive karta-transparent karta-transparent-1" />
                    <img src="img/cards/card-back.png" class="card card-selected img-responsive karta-transparent karta-transparent-2" />
                    <img src="img/cards/card-back.png" class="card card-selected img-responsive karta-transparent karta-transparent-3" />
                    <img src="img/cards/card-back.png" class="card card-selected img-responsive karta-transparent karta-transparent-4" />
                    <img src="img/cards/card-back.png" class="card card-selected img-responsive karta-transparent karta-transparent-5" />
                    <img src="img/cards/card-back.png" class="card card-selected img-responsive karta-transparent karta-transparent-6" />
                  </div>
                </div>
                
                <div className="hide-on-reveal">
                  <a href="#" onClick={this.shuffleCards} class="btn btn-tarot">PROMEŠAJTE KARTE</a>
                  <h6>Pre nego što odaberete karte, razmislite o pitanju na koje želite jasan odgovor.<br/>Tada možete odabrati 3 karte iz tarot špila i dobiti besplatno čitanje tarota!</h6>
                </div>
                
                <div className="analiza-wrapper" ref={(instance)=>{this.analizaWrapperRef = instance;}}>
                  <div class="container">
                    <div className="col-sm-8 col-sm-offset-2">
                      <h6 style={{fontSize: "18px"}} ref={(instance)=>{this.resultSubTitleRef = instance;}}></h6>
                      <p ref={(instance)=>{this.resultDescRef = instance;}}></p>
                      <p><span class="gradiant-txt" ref={(instance)=>{this.resultQuestionRef = instance;}}></span></p>
                      <p ref={(instance)=>{this.resultAnswerRef = instance;}}></p>
                      {this.state.isTarotLanding2 && window.matchMedia('(max-width:1023px)').matches //isMobile
                        ? <p style={{marginTop: "32px"}}><span style={{fontSize: "14px"}}>Za detaljno tumačenje karata klikni tu:</span></p>
                        : <p style={{marginTop: "32px"}}><span class="gradiant-txt" style={{fontSize: "16px"}}>SAZNAJTE ODGOVORE U TAROT KARTMA KOJE STE OTVORILI !!!</span></p>
                      }
                      <div class="hide-on-mobile">
                          <div class="row">
                              <div class="col-sm-8 col-sm-offset-2">
                                  <a ref={(instance)=>{this.resultSMSRef = instance;}} style={{display: "block", textDecoration: "none", outline: "none", userSelect: "none", color: "white", margin: 'auto'}}>
                                    <div class="button-animation" style={{padding: "8px"}}>
                                      <h4 style={{marginBottom: 0}}>Pošaljite svoj lični kod: {this.state.resultSMSCode}<br/>SMS-om na broj 8807</h4>
                                      <h5 style={{marginTop: "16px", fontSize: "14px"}}>Ovo je pretplatna usluga, 2x nedeljno po 60 din (PDV uključen)</h5>
                                    </div>
                                  </a>
                              </div>
                          </div>
                      </div>

                      <div class="hide-on-desktop">
                        {/* <div class="space"></div> */}
                        <a ref={(instance)=>{this.resultSMSMobileRef = instance;}} style={{display: "block", textDecoration: "none", outline: "none", userSelect: "none", color: "white", maxWidth: "400px", margin: 'auto'}}>
                          <div class="button-animation" style={{padding: "4px 8px"}}>
                            {this.state.isTarotLanding2
                              ? <h4 style={{marginBottom: "16px", marginTop: "16px"}}>PREUZMITE DETALJNO TUMAČENJE</h4>
                              : <>
                                  <h5 style={{marginBottom: 0}}>Pošaljite svoj lični kod: {this.state.resultSMSCode}<br/>SMS-om na broj 8807</h5>
                                  <h6 style={{marginTop: "16px", fontSize: "14px"}}>Ovo je pretplatna usluga, 2x nedeljno po 60 din (PDV uključen)</h6>
                                </>
                            }
                          </div>
                        </a>
                        {/* <p style={{fontSize: "13px", lineHeight: 1, marginTop: "-20px", color: "#c1d9ef", opacity: 0.5}}>Pošaljite SMS-om vaš osobni kod TK4 22C na 862862. Saznajte odgovore u tarot kartama koje ste otvorili. Ovo je pretplatnička usluga. 9.90 kn/SMS max 2 x tjedno. Zaigrajte kvizove iznenađenja i otkrijte što vam donosi budućnost!</p> */}
                      </div>

                      {/* {this.state.isTarotLanding2 //isMobile
                        ? <p class="hide-on-desktop" style={{marginTop: "32px"}}><span style={{fontSize: "13px", opacity: 0.8}}>Pošaljite svoj lični kod {this.state.resultSMSCode} SMS-om na broj 8807 I saznajte odgovore u tarot kartama koje ste otvorili.Ovo je pretplatna usluga, 2 puta nedeljno po 60 din (PDV uključen)</span></p>
                        : ""
                      } */}
                    </div>
                  </div>
                </div>
            </div>
            <div class="container" style={{display: "flex", flexDirection: "column", flex: 1, justifyContent: "flex-end"}}>
              <div className="col-sm-8 col-sm-offset-2">
                <div className="col-sm-12 note">
                  <div>
                    <p><small style={{fontSize: "13px"}}>Ovaj servis je namenjen punoletnim osobama. Tekstovi koji su dostupni na sajtu predmet su autorskog prava i zaštićeni Zakonom o autorskom i srodnim pravima. Usluga je namenjena za zabavu. Cena pretplatne usluge je 60 dinara sa PDV (+ cena standardne MO SMS poruke: MTS 3,60 Din, Telenor 3,60 Din, VIP 3,48 Din, Globaltel-besplatno) maks 2 puta nedeljno. Za deaktivaciju usluge sledite upute poslane u sms poruci nakon aktivacije servisa. Comparo media d.o.o je vlasnik ovog servisa i snosi punu odgovornost prema njegovim korisnicima. Tehnički pružalac usluge NTH Media d.o.o. Beograd, info: +381113216866, mail: comparo.rs@silverlines.info</small></p>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>);
  }

  _getCardLeft(index) {
    var isMobile = window.matchMedia('(max-width:1023px)').matches;
    if(isMobile) {
      return `calc(100vw / 12 * ${index + 1} - (4% * ${index + 1}))`;
    }
    switch(index) {
      case 0: return "19%";
      case 1: return "23.7%";
      case 2: return "28.4%";
      case 3: return "33.1%";
      case 4: return "37.8%";
      case 5: return "42.5%";
      case 6: return "47.2%";
      case 7: return "51.9%";
      case 8: return "56.6%";
      case 9: return "61.3%";
      case 10: return "66%";
      case 11: return "70.7%";
    }
  }

  shuffleCards() {
    let count = 0;
    let startShuffle = ()=>{
      this._shuffle();
      if(count < 6) {
        setTimeout(startShuffle, 300);
        count++;
      }
    }
    startShuffle();
  }

  _shuffle(){
    let newOrder = this.state.cards.map((card) => card.number).sort((a,b)=>Math.random()>0.5? 1: -1)
    var cards = this.state.cards.map((card, index) => {
      card.number = newOrder[index];
      return card;
    });
    this.setState({cards: cards});
  }

  async selectCard(e) {
    const cardToSelect = e.currentTarget;

    if(cardToSelect.classList.contains("card-selected")) return;

    const selectedCards = this.state.selectedCards;
    selectedCards.push(parseInt(cardToSelect.getAttribute("id")));
    this.setState({selectedCards: selectedCards});

    this._adjustSelectedCard(selectedCards.length, cardToSelect);
    
    if(this.state.selectedCards.length === 3) {
      this.cardsWrapperRef.classList.add("cards-reveal");

      const res = await fetch('https://rs.astro24.net' + '/api/api.php?action=getTarotResult&cards=' + this.state.selectedCards.join(','));
      const response = await res.json();

      const result = response["result"];
      this.displayResults(result);
    }
  }

  _adjustSelectedCard(cardPosition, cardToSelect) {
    cardToSelect.classList.add('card-selected');
    cardToSelect.classList.add('card-selected-' + cardPosition);
    cardToSelect.style["transition"] = "all 0.3s ease-in-out";
  }

  async displayResults(result, resultIsPreExistent) {
    Object.entries(result["cards"]).forEach((card, index) => {
      const cardSelected = this.cardsWrapperRef.querySelector(`[id="${this.state.selectedCards[index]}"]`)
      const cardBackImgEl = cardSelected.querySelector(".card-flip-back img");
      const cardTitleEl = cardSelected.querySelector(".card-title");

      if(card["1"]["direction"] === "backward") {
        cardBackImgEl.parentNode.classList.add('backward');
      }

      cardTitleEl.innerHTML = card["1"]["name"];
      cardBackImgEl.setAttribute("src", card["1"]["src"]);
    }, this);
    this.resultSubTitleRef.innerHTML= result["subtitle"];
    this.resultDescRef.innerHTML= result["desc"];
    this.resultQuestionRef.innerHTML= result["question"];
    this.resultAnswerRef.innerHTML= result["answer"];
    
    this.resultSMSRef.setAttribute("href", "sms:8807?&body=" + result["sms"]);
    this.resultSMSMobileRef.setAttribute("href", "sms:8807?&body=" + result["sms"]);
    this.setState({resultSMSCode : result["sms"]});

    this.titleRef.style["opacity"] = 0;
    this.titleResultRef.style["opacity"] = 1;
    this.rootRef.classList.add("root-cards-revealed");
    this.cardsWrapperRef.classList.add("cards-revealed");

    var ro = new ResizeObserver(entries => {
      const isMobile = window.matchMedia('(max-width:1023px)').matches; if(!isMobile) return;
      for (let entry of entries) {
        const cr = entry.contentRect;
        this.cardsWrapperRef.style.height = `${cr.height}px`;
      }
    });
    ro.observe(this.analizaWrapperRef);

    // const countdownIntervalId = setInterval(this._decrementResultCountdown.bind(this), 1000);
    // this.setState({resultCountdownIntervalId : countdownIntervalId});

    if(!resultIsPreExistent) {
      localStorage.setItem('selectedCards', JSON.stringify(this.state.selectedCards));
      localStorage.setItem('selectedCardsResult', JSON.stringify(result));
      localStorage.setItem('selectedCardsExpire', JSON.stringify(Date.now()));
    }
  }
}
export default withRouter(Tarot);