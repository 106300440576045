import React from "react";


class Terms extends React.Component {
  render() {
    return(
      <div class="wrapper">
        <div class="header">
            <nav class="navbar navbar-default">
                <div class="container">
                    <div class="navbar-header">
                        <a class="navbar-brand" href={window.location.href.replace(/\/terms/g, '')}><img src={`${process.env.PUBLIC_URL}/img/logo.png`} width="200px"/></a>
                    </div>
                </div>
            </nav>
        </div>
        <div class="main-content">
          <div class="container uputstvo" style={{paddingTop: 0}}>
            <div class="col-sm-12"> 
                <div class="row" style={{paddingBottom: "5px"}}>
                  <h2><strong>Uslovi korišćenja usluge „Astro24“</strong></h2>
                </div>
                <div class="row" style={{marginTop: "32px", textAlign: "start"}}>              
                  <p>1. Usluga „Astro24“ je dostupna korisnicima mobilnih mreža operatera: MTS, Telenor, Vip, Globaltel</p>
                  <p>2. Usluga „Astro24“ služi jedino i isključivo za zabavu.</p>
                  <p>3. Da biste dobili pristup uslugama koje se nude na sajtu treba da pošaljete SMS poruku na određen broj, naveden na veb sajtu.</p>
                  <p>4. Korisnik je isključivo odgovoran za slanje SMS poruke koja sadrži pravilan sadržaj (tekst SMS-a) i pravilan broj. Ako pošaljete SMS poruku sa netačnim sadržajem ili na pogrešan broj, dobićete odgovor o grešci.</p>
                  <p>5. Pružalac usluge je Comparo Media d.o.o. Tehnički pružalac usluge NTH Media d.o.o. Beograd.</p>
                  <p>6. Ovo je pretplatna usluga. Cena 60 din. (PDV uključen) maksimalno 2 puta nedeljno.</p>
                  <p>7. Za deaktivaciju usluge u bilo kojem trenutku sledite upute poslane u sms poruci nakon aktivacije servisa.</p>
                  <p>8. Pružalac usluge zadržava svoje pravo da promeni pravila korišćenja usluge u svako doba. Pružalac usluge će objaviti nove uslove na Internet stranici.</p>
                  <p>9. Korisnik je saglasan da koristi uslugu u skladu sa tim pravilima i isključivo na način koji je u skladu sa primenjivim pravom, pravilima o socijalnom ponašanju i moralu, uz poštovanje prava intelektualne svojine.</p>
                  <p>10. Slanjem SMS poruke korisnik izražava svoju saglasnost da dobija obaveštenja o akcijama i drugim uslugama koje se nude preko pružaoca.</p>
                  <p>11. Mobilni operateri su odgovorni samo za ostvarivanje telekomunikacione usluge i ne snose odgovornost za ponuđene usluge, posebno sadržaj i informacije, koje oni nude.</p>
                  <p>12. Pružalac ne snosi odgovornost za neispravnost usluge kao rezultat nepravilnog funkcionisanja mreže odgovarajućeg mobilnog operatera</p>
                  <p>13. Služba za korisnike radnim danom od 09-17h, +381113216866, mail: comparo.rs@silverlines.info.</p>
                  <p>14. Sadržaj veb sajta je predmet autorskog prava i zaštićen je Zakonom o autorskom pravu i njemu srodnim pravima.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Terms;